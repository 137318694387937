import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction to `}<inlineCode parentName="h2">{`step`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`step`}</inlineCode>{` is an easy-to-use CLI tool for building, operating, and automating Public Key Infrastructure (PKI) systems and workflows. `}<inlineCode parentName="p">{`step`}</inlineCode>{` acts as front-end interface to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/certificate-manager"
      }}>{`Certificate Manager`}</a>{` and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode></a>{`, an online X.509 and SSH Certificate Authority (CA). `}<inlineCode parentName="p">{`step`}</inlineCode>{` is also a standalone, general-purpose PKI toolkit: You can use it for many common crypto and X.509 operations.`}</p>
    <h2>{`Using `}<inlineCode parentName="h2">{`step`}</inlineCode>{` with `}<inlineCode parentName="h2">{`step-ca`}</inlineCode></h2>
    <p>{`If you'd like to use `}<inlineCode parentName="p">{`step`}</inlineCode>{` with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, head over to the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` documentation`}</a>{` for command usage information and examples.`}</p>
    <h2>{`Examples that don't require `}<inlineCode parentName="h2">{`step-ca`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`step`}</inlineCode>{` has plenty of features that make crypto easier for the casual user.`}</p>
    <p>{`Here's a few common uses of the `}<inlineCode parentName="p">{`step`}</inlineCode>{` command that don't require `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#create-and-work-with-x509-certificates"
        }}>{`Create and work with X.509 certificates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#get-a-tls-certificate-from-lets-encrypt"
        }}>{`Get a TLS Certificate From Let's Encrypt`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#generate-json-web-tokens-jwts-and-json-web-keys-jwks"
        }}>{`Generate JSON Web Tokens (JWTs) and JSON Web Keys (JWKs)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#obtain-and-work-with-oauth-tokens"
        }}>{`Obtain and Work With OAuth Tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#work-with-ssh-certificates"
        }}>{`Inspect an SSH certificate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#sign-and-encrypt-arbitrary-data"
        }}>{`Sign and encrypt arbitrary data using the NaCl library`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations#generate-totp-tokens-for-multi-factor-authentication-mfa"
        }}>{`Generate and verify TOTP tokens for multi-factor authentication (MFA)`}</a></li>
      <li parentName="ul">{`Add and remove CA certificates from your system's trust store`}</li>
    </ul>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/the-step-command"
        }}>{`The `}<inlineCode parentName="a">{`step`}</inlineCode>{` Command`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/basic-crypto-operations"
        }}>{`Basic Crypto Operations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference"
        }}>{`Command Reference`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      